import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@vds/buttons';
import { Body, Feature, Title } from '@vds/typography';

import { pageLoadStarted, userRegistrationClick ,pageLoadCompleted} from '../common/Analytics/GAWrapper';
import './GrantsComponent.scss';
import { Col, Grid, Row } from '@vds/grids';
import { updateRegisterTriggeredFrom } from '../store/aem';
import store from '../store/store';
import React, { useState, useRef,useEffect } from 'react';
const GrantsComponent = () => {
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  const aemData = useSelector((state) => state.Aem.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  const handleClick = () => {
    store.dispatch(updateRegisterTriggeredFrom('/grants'));
    localStorage.setItem('prevUrl',window.location.href);
    navigate('/onboarding', { replace: true });
   
    userRegistrationClick('Grants', aemData?.offerandIncentives?.registerCTALink, window.location.host + '/onboarding');
    
  };


  return (
    <section aria-label="start-learning" className="grants-container pv-32X">
      <Grid
        className=""
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}>
        <Row>
          <Col>
            <div aria-label= {aemData?.offerandIncentives?.heroFactTxt +aemData?.offerandIncentives?.heroFactTxt2} tabIndex={0}>
              <Feature
                size={isMobile ? 'small' : 'large'}
                color="#000000"
                bold={true}
              >
                {aemData?.offerandIncentives?.heroFactTxt}
                <br />
                {aemData?.offerandIncentives?.heroFactTxt2}
              </Feature>
            </div>
            <div aria-label={aemData?.offerandIncentives?.subTitleTxt +" $10,000" +aemData?.offerandIncentives?.subTitleTxt2} className="mt-8X" tabIndex={0}>
              <Title size='medium'>
                {aemData?.offerandIncentives?.subTitleTxt}
                <Title
                  size='medium'
                  primitive="span"
                  bold={true}
                >
                  {' '}
                  $10,000{' '}
                </Title>
                {aemData?.offerandIncentives?.subTitleTxt2}
              </Title>
            </div>
            <div aria-label="buttonArea" className="button-area mt-12X">
              <Button
                size="large"
                disabled={false}
                use="primary"
                onClick={handleClick}
              >
                {aemData?.offerandIncentives?.registerCTALink}
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
    </section>
  );
};

export default GrantsComponent;
