import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Results from './components/results/Results'
import { LayoutTokens } from '@vds-tokens/layout';
import { VDSManager } from '@vds/utilities';

import App from './App';
import OnboardingLanding from './components/onboarding/onboardingLanding';
import './config/config';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import CourseContentPage from './views/CourseContentPage';
import LandingPage from './views/LandingPage';
import OnboardingPage from './views/onboarding/OnboardingPage';
import Privacy from './views/privacy/privacy'
import GrantsPageComponent from './views/Grants'
import CategoryLandingComponent from './components/Category/CategoryLandingComponent.jsx'
import Networking from './components/Networking.jsx';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { useEffect } from 'react';
import { pageLoadStarted } from './common/Analytics/GAWrapper.jsx';
import i18n from './components/React-i18Next/I18next.js';
import { Helmet } from 'react-helmet';
import CourseContentWrapperComponent from './views/CourseContentWrapperComponent.jsx';
import GrantsWrapperComponent from './views/GrantsWrapperComponent.jsx';
import ScrollTo from './components/common/ScrollTo.jsx';
const cssRoot = document.querySelector(':root');
// set css variable
for (let tokens in LayoutTokens.space) {
  cssRoot.style.setProperty(
    '--space' + tokens,
    LayoutTokens.space[tokens].value
  );
  console.log(tokens);
}
const getPageTitle = () =>{
  let entries = {
    '/onboarding': 'Onboarding',
    '/account/settings': 'Account Settings',
    '/networking': 'Networking',
    '/grants': 'Grants',
    '/results/branding': 'Branding',
    '/results/funding': 'Funding',
    '/results/onlinemarketing': 'Online Marketing',
    '/results/winningcustomers': 'Winning Customers',
    '/results/websitedesign': 'Website Design',
    '/results/legalessentials': 'Legalessentials',
    '/results/operations': 'Operations',
    '/results/socialmedia': 'Socialmedia',
    '/results/strategy': 'Strategy',
    '/results/cybersecurity': 'Cybersecurity',
    '/results/managingmoney':'Wellness',
    '/results/managingmoney':'Managing Money',
    '/category/offers': 'Offers',
    '/category/courses': 'Courses',
    '/category/events': 'Events',
    '/category/quicktips': 'Quick Tips',
    '/':'Landing'
  };
  let pageTitle = "Verizon Small Business Digital Ready";
console.log("entries[location.pathname",entries[location.pathname])
  if (entries[location.pathname]) {
    pageTitle = pageTitle + ' - ' + entries[location.pathname];
  }
 
  return pageTitle;
}
// useEffect(() => {
//   console.log("Componet mounted");
// }, [])
console.log("useeffect inside app.jsx")
// const language=localStorage.getItem('lng')!==''?localStorage.getItem('lng'):'en';
// localStorage.setItem('lng', isSpanish ? 'es' : 'en');
const location = window.location;
const path = location.pathname;
const storel = window.localStorage;
let url = '';
let prevUrl = '';
console.log("Language",localStorage.getItem("lng"))
url = storel.getItem('url');
storel.setItem('prevUrl', url);
storel.setItem('url', path);
console.log("Url", storel.getItem("prevUrl"))
console.log("Url current", storel.getItem("url"))
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Provider store={store}>
    <Header/>
    <ScrollTo/>
    <Helmet htmlAttributes={{ lang: localStorage.getItem("lng") ? localStorage.getItem("lng") : "en" }} />
        <Helmet>
          <title>{getPageTitle()}</title>
        </Helmet>
      <VDSManager />
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<LandingPage />}></Route>
            <Route path="/welcome" element={<LandingPage />}></Route>
            <Route path="/onboarding" element={<OnboardingPage />}></Route>
            <Route path="/privacy/your-privacy-choices" element={<Privacy />}></Route>
            <Route path="/grants" element={<GrantsWrapperComponent />}></Route>
            <Route path="/results/:topicType" element={<Results/>} ></Route>
            <Route path="/category/:categoryType" element={<CategoryLandingComponent/>} />
            <Route
              path="/course-details/:courseId"  element={<CourseContentWrapperComponent />}
            ></Route>
            <Route path="/learning-details/:courseId" element={<CourseContentWrapperComponent/>}></Route>
            {/* <Route path='/course-details/:courseId' exact render= {routeProps =><CourseContentPage {...routeProps} key={document.location.href} />} /> */}
            <Route path="/networking" element={<Networking />}></Route>
            <Route path="*" element={<LandingPage />}></Route>
          </Route>
          <Route path="*" element={<App />}></Route>
        </Routes>
      </Router>
    </Provider>
    <Footer/>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//console.log("Inside root")
//pageLoadStarted(language,"hi","welcome");
