import React, { useEffect, useState } from 'react';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';

import FiltersAndSortComponent from './Filtersandsort.jsx';
import CategoryHeadersComponent from './categoryHeader.jsx';
import OffersFilteredResultsComponent from './OffersResult.jsx';
import { cloneDeep } from 'lodash';
import { forcedRankingOffers } from '../../../utility/utils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import store from '../../../store/store.js';
import { updateRegisterTriggeredFrom } from '../../../store/aem.js';
const OffersCategoryLandingComponent = () => {
  const navigate=useNavigate();
  let lng = localStorage.getItem("lng") && localStorage.getItem("lng") === 'es' ? 'es' :'en';
  if(lng === null){
    lng = userDataFromStore?.business?.attributes?.Language??'en';
  }
  console.log("global.config.URL_MAPPING_AUTHENTICATED_USER", global.config.URL_MAPPING_AUTHENTICATED_USER);
  const udpatedCiamSignInURL = global.config.CIAM_SIGN_IN_URL+encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[location.pathname]}?ciamLang=${lng}`);
  const [offersData,setOffersData] = useState([]);
  const [offers,setOffers] = useState([]);
  const [offerCategories, setOffersCategories] = useState([]);
  const [processedOffers,setProcessedOffers] = useState([]);
  const [offersResults,setOfferResults] = useState([]);
  const [NUMBER_OF_TILES,setNUMBER_OF_TILES] = useState(8);
  const [totalPages,setTotalPages] = useState(0);
  const [selectedSortOption,setSelectedSortOption] = useState({})
  const aemData = useSelector((state) => state.Aem.data);
  const aemOffersData = useSelector((state) => state.Aem.offerData);
  let imgUrl = 'https://s3-alpha-sig.figma.com/img/2bcc/fe58/ba42358b65e3d825e065334eac8b78fe?Expires=1709510400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Loj8Cy2Fa5mp5ncYk6I5zPqZUjKM0AlC04IQk04XVE99YDIbQvAP4VSYFGRoYzaIOkYqq6zkEvbN2v1mal-dMAnXj9a1tkGzVks5r66WpxpasyAeTq-qbwixHGgMR-kXAY~p7uQWcMt99Le1olQUDWHNmPp~YzCY80Nl4wUmF-ng0ScSKtIM1DuAvlhzxC7JP-4SPOPW9B0COrN3Idjws40dyVhgVV6xCFs2GCAsTYYFCxdkEjHODE0a-Kr3X6ttJaPVN8Z7qqJU-HQ0xnP4XIhWD84qMGZ~IWoSpCjWbo9-dOMwITyD9VfN76qpqfQt4hJjahNaB0mOplMXqGhx-w__';
  let bgStyle = {
    'background': `linear-gradient(0deg, rgba(246, 246, 246, 0.50) 0%, rgba(246, 246, 246, 0.50) 100%), linear-gradient(90deg, #FFF 24.72%, rgba(255, 255, 255, 0.00) 97.08%),
     url(`+ imgUrl + `), lightgray 273.519px -239.75px / 114.2% 228.281% no-repeat;`,
    'background- blend - mode': 'screen, normal, normal;'
  };
  console.log('background',bgStyle)
  const dispatch = useDispatch();

  let pageData = {
    category: {
      label: aemData?.offers?.offerTxt,
      subText: aemData?.offers?.offersdes,
      textLink: { label: '', link: '', tooltipText: '' },
      buttons: [
        {
          children: aemData?.offers?.registerTxt,
          size: 'large',
          use: 'primary',
          width: 'auto',
          onClick: (e) => { store.dispatch(updateRegisterTriggeredFrom(location.pathname));
            localStorage.setItem('prevUrl',window.location.href);
            navigate('/onboarding');
         
          },
        },
        {
          children: aemData?.offers?.signIn,
          size: 'large',
          use: 'textLink',
          width: 'auto',
          href:udpatedCiamSignInURL ,
          onClick: () => {document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`},
        },
       
      ],
      sideTextLink: { label: '', link: '' },
      sideIcon: { name: '', color: '' }
    },

  };

  const filterResults = (selectedFilters) => {
    if(selectedFilters[0].label == "All"){
      setProcessedOffers(offers);
    }
    else{
      let tempFilteredResults = [];
      selectedFilters.map((item)=>{
        offers.map((offeritem)=>{
          if(offeritem?.category == item?.label){
            tempFilteredResults.push(offeritem);
          }
        });
      });
      setProcessedOffers(tempFilteredResults);
    }
  }
  



  const sortResults = (selectedSortOption) =>{
    setSelectedSortOption(selectedSortOption);
    if(selectedSortOption == aemData?.offers?.Featured){
      const sortedresults = forcedRankingOffers(offersData);
      setProcessedOffers(sortedresults);
    } else {
      const dateSortedResults = offers.sort((a,b) => new Date(b.datePublished) - new Date(a.datePublished))
      setProcessedOffers(dateSortedResults);
    }
  }

  useEffect(()=>{
    let tempcategory = [{id:1,label:"All",data:"All"}];
    setOffersData(aemOffersData);
    setOffers(Object.values(aemOffersData?.offers[0])?.filter((item)=>item.format == 'Offer'));
    setProcessedOffers(Object.values(aemOffersData?.offers[0])?.filter((item)=>item.format == 'Offer'));
    Object.values(aemOffersData?.offers[0]).map((item,n)=>{
      if(tempcategory.filter((filter)=> filter.label == item.category).length == 0 && item.category != undefined){
        tempcategory.push({id:1,label:item.category,data:item.category});
      }
    });
    setOffersCategories(tempcategory);
  },[]);
  
  useEffect(()=>{
    let clonedData = cloneDeep(processedOffers);
    setOfferResults(clonedData.splice(0,NUMBER_OF_TILES));
    setTotalPages(Math.round(processedOffers.length/NUMBER_OF_TILES));
  },[processedOffers])

  const handlePagination = (page) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let clonedData = cloneDeep(processedOffers);
    setOfferResults(clonedData.splice((page - 1)*NUMBER_OF_TILES, NUMBER_OF_TILES));
  }

  return (
    <section>
      <div className='categories-header bg-gray image-bg'>
        <Grid
          bleed="1440"
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}
        >
          <Row>
            <Col>
              <div className='mv-8X'>
                <Breadcrumbs surface="light">
                  <BreadcrumbItem href="/">{aemData?.grants?.homeTxt}
                  </BreadcrumbItem>
                  <BreadcrumbItem>{pageData.category.label}
                  </BreadcrumbItem>
                </Breadcrumbs>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <CategoryHeadersComponent props={pageData.category} />
            </Col>
          </Row>
        </Grid>
      </div>
      <div className='bg-white results-section pt-8X pb-16X'>
        <Grid
          bleed="1440"
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}
        >
          <Row>
            <Col>
              <FiltersAndSortComponent options={offerCategories} selectedSort={selectedSortOption} sortResults={sortResults} filterResults={filterResults} chips={true}></FiltersAndSortComponent>
            </Col>
          </Row>
          <Row>

            <Col colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}>
              {offersResults.length>0 && <OffersFilteredResultsComponent  totalPages={totalPages} offers={offersResults} handlePagination={handlePagination}></OffersFilteredResultsComponent>}
            </Col>
          </Row>
        </Grid>
      </div>
    </section >
  );
};


export default OffersCategoryLandingComponent;
