import { useSelector } from 'react-redux';

import { TileContainer } from '@vds/tiles';
import { Title } from '@vds/typography';

import './ResourceCardComponent.scss';

const ResourcesCardComponent = (props) => {
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  return (
    <div  tabIndex={0}>
    <TileContainer

      viewport={'desktop'}
      className="tileContainer"
      aspectRatio="1:1"
      width="100%"
      showDropShadow={true}
      backgroundColor={props.bgColor}
      layout={isMobile ? '1UP' : '3UP'}
      padding='0px'
    
    >
      <div className="resourceCard">
        <div className="description">
          <div className="label">
            <Title bold={false} color={props.color} size="large">
              {props.label}
            </Title>
          </div>
        </div>
        <div className="imageDisplay">
          <img
            src={isMobile ? props?.mobileimg : props.image}
            alt={""}
          />
        </div>
      </div>
    </TileContainer>
    </div>
  );
};

export default ResourcesCardComponent;
