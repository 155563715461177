import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { DropdownSelect } from '@vds/selects';
import { Body } from '@vds/typography';
import { Icon } from '@vds/icons';
import { Button, TextLink } from '@vds/buttons';
import { Line } from '@vds/lines';
import { Modal, ModalBody, ModalTitle, ModalFooter } from '@vds/modals';

import CategoryFiltersComponent from './CategoryFiltersComponent.jsx';

import '../CategoryPage.scss';
import '../Categories.scss';


const CategoryFiltersAndSortComponent = ({ filterSortData, filters, removeSorting, sortingValues, handleSorting, handleRemoveFilter, handleFilter, handleRemoveAllFilter, resultSize, headingData}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [showMobileFilter, setShowMobileFilter] = useState(false);

  console.log("CategoryFiltersAndSortComponent", filters);

  return (
    <div className='u-flex-apart m-flex-colrev pv-8X pb-16X'>
      <div className='width70'>
        {filterSortData && (
          <div className='u-flex-wrap'>
            {filterSortData.map((item, n) => {
              return (
                <div key={n} className='u-flex chips mr-3X mb-3X'>
                  <Body size='small'>{t(item.label)}</Body>
                  <span className='ml-2X u-flex' onClick={e=>handleRemoveFilter(item.value, item.label)} tabIndex={0} aria-label={'remove filter ' + item.label}> <Icon name="close" size="small" color="#000000" /></span>
                </div>);
            })}
            {filterSortData.length > 0 ? (
              <span className='mb-2X'>
                <TextLink type="standAlone"
                  size='small'
                  onClick={e => handleRemoveAllFilter()} role='button' ariaLabel={t('Clear All')}>
                  {t('Clear All')}
                  
                </TextLink>
              </span>

            ) : ''}
            {filterSortData.length == 0 ? (
              <span className='u-flex chips mb-2X'>
                <Body size='small'>{t('No filters selected')}</Body>
              </span>

            ) : ''}
          </div>
        )}
      </div>
      {isMobile && (
        <div className='pv-4X'> <Line type="secondary" /> </div>
      )}
      <div className='u-flex-apart sort-by'>
        {/* <div className='mr-8X'>
          <Body size='large' color='grey'>Showing {totalResults} results</Body>
        </div> */}
        {isMobile && (
          <div className='u-flex'>
            <Button use='secondary' size='small' onClick={(e) => setShowMobileFilter(true)}> Filter </Button>
            <Modal onOpenedChange={(e)=>{if(!e){setShowMobileFilter(false)}}} opened={showMobileFilter} surface="light" fullScreenDialog={false} disableAnimation={false} disableOutsideClick={false} ariaLabel="Filters" >
              <ModalTitle>Filter</ModalTitle>
              <ModalBody>
                <CategoryFiltersComponent filters={filters} handleFilter={handleFilter} headingData={headingData} handleRemoveAllFilter={handleRemoveAllFilter} setShowMobileFilter={setShowMobileFilter}></CategoryFiltersComponent>
              </ModalBody>
            </Modal>
            <div className='ml-2X'>
            {!removeSorting && sortingValues && (
              <DropdownSelect onChange={e => handleSorting(e.target.value)} label={t("Sort by:")} errorText='Select a state' error={false} disabled={resultSize == 0 ? true : false} readOnly={false} inlineLabel={true} >
                {sortingValues.map(e => <option value={e.value}>{t(e.label)}</option>)}
              </DropdownSelect>)}
            </div>
          </div>
        )}
        {resultSize == 0 && !isMobile && <Body size='medium'>{t("Showing 0 results")}</Body>}
        {!isMobile && !removeSorting && sortingValues && (
          <DropdownSelect onChange={e => handleSorting(e.target.value)} label={t("Sort by:")} errorText='Select a state' error={false} disabled={resultSize == 0 ? true : false} readOnly={false} inlineLabel={true} >
            {sortingValues.map(e => <option value={e.value}>{t(e.label)}</option>)}
          </DropdownSelect>)}
      </div>
    </div>
  );
};

export default CategoryFiltersAndSortComponent;

