import * as forge from 'node-forge'

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { Input } from '@vds/inputs';
import { ListItem, UnorderedList } from '@vds/lists';
import { Micro } from '@vds/typography';

import { ruless, rulessSpanish } from '../../../../assets/mockdata/registermock';
import EmailInputComponent from '../../../../elements/inputs/emailInput';
import PasswordValidatorComponent from '../../../../elements/inputs/passwordValidator';
import {
  updateRegistrationStepCaptchaToken,
  updateRegistrationStepUserDetails,
  updateBusinessName,
  registerPassword
} from '../../../../store/aem';
import store from '../../../../store/store';
import './RegistrationComponent.scss';

const RegistrationComponent = (props) => {
  const { aemData } = props;

  const isSpanish = useSelector((state) => state.Aem.isSpanish);

  const [businessNameValue, setBusinessNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  let key = null;

  useEffect(() => {
    if (isEmailValid && isPasswordValid && isRecaptchaVerified) {
      const encryptedPassword = encryptPassword(passwordValue, 'XNxG6UyvvqxSyd43nu25CX8E6VuNz//HzIjyFrDrz9s=');
      console.log("encryptedPassword--> ", encryptedPassword);

      store.dispatch(updateBusinessName({businessName: businessNameValue}));
      store.dispatch(registerPassword({password: passwordValue}));

      store.dispatch(
        updateRegistrationStepUserDetails({
          businessName: businessNameValue,
          email: emailValue,
          password: encryptedPassword
        })
      );
    } else {
      store.dispatch(updateBusinessName({businessName: ''}));
      store.dispatch(registerPassword({password: ''}));
      store.dispatch(
        updateRegistrationStepUserDetails({
          businessName: '',
          email: '',
          password: ''
        })
      );
    }
  }, [
    isEmailValid,
    isRecaptchaVerified,
    isPasswordValid,
    businessNameValue,
    emailValue,
    passwordValue,
  ]);

  const updateEmailInfo = (data, isValid) => {
    setEmailValue(data);
    setIsEmailValid(isValid);
  };

  const updatePasswordInfo = (data, isValid) => {
    setPasswordValue(data);
    setIsPasswordValid(isValid);
  };

  const verifyToken = (val) => {
    if (val) {
      store.dispatch(updateRegistrationStepCaptchaToken(val));
      setIsRecaptchaVerified(true);
    } else {
      setIsRecaptchaVerified(false);
    }
  };

  const encryptPassword = (password, key) => {

    //var key1 = forge.random.getBytesSync(16);
    //var iv1 = forge.random.getBytesSync(16);
    const myBuffer =Uint8Array.from(atob(key), c => c.charCodeAt(0));
    const hbuff = forge.util.createBuffer(myBuffer);
    const iv1 = new Uint8Array(12);
    const cipher = forge.cipher.createCipher('AES-GCM', hbuff);
    cipher.start({
		iv: iv1,
         //additionalData: 'nvn', // optional
         tagLength: 128 // optional, defaults to 128 bits
    });
    cipher.update(forge.util.createBuffer(password));
    cipher.finish();
    const encrypted = cipher.output;
    const encodedB64 = forge.util.encode64(encrypted.data);
    const tag = cipher.mode.tag;
    const tagB64 = forge.util.encode64(tag.data);
    const transmitmsg = tagB64+encodedB64;
    return transmitmsg;
   
};

  return (
    <div className="input-container mt-8X sm-mt-6X ">
      <div className="mb-6X">
        <Input
          type="text"
          label={aemData?.registerModule?.passwordValidator?.businessNameLbl}
          readOnly={false}
          required={false}
          disabled={false}
          error={false}
          onChange={(e) => setBusinessNameValue(e.target.value)}
        />
      </div>
      <div className="mb-6X n-textLeft">
        <EmailInputComponent
          type="text"
          label={aemData?.registerModule?.passwordValidator?.emailLabel}
          readOnly={false}
          required={true}
          disabled={false}
          errorText={aemData?.registerModule?.passwordValidator?.pwdErrorTxt}
          childToParent={updateEmailInfo}
          existingEmail={props?.parentemail}
          message={props?.message}
          captchaError={aemData?.errorMessages?.captchaErrTxt}
          emailErr={aemData?.errorMessages?.notificationTitleTxt}
        />
      </div>
      <div className="mb-1X">
        <PasswordValidatorComponent
          data={emailValue}
          type="password"
          label={aemData?.registerModule?.passwordValidator?.passwordlbl}
          readOnly={false}
          required={true}
          disabled={false}
          error={false}
          success={true}
          rules={isSpanish ? rulessSpanish : ruless}
          errorText={aemData?.registerModule?.passwordValidator?.errorTxt}
          helperText={aemData?.registerModule?.passwordValidator?.helperText}
          helperTextPlacement="bottom"
          childToParent={updatePasswordInfo}
          tooltip={{
            title: aemData?.registerModule?.passwordToolTip?.passwordTitleTxt,
            children: (
              <>
                {/* <div><span>In order to meet security requirements, all passwords must: </span>
                                                    </div>
                                                    {'\n'}  */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ paddingBottom: '12px' }}>
                    <Micro size="medium">
                      {
                        aemData?.registerModule?.passwordToolTip
                          ?.passwordSubTitleTxt
                      }
                    </Micro>
                  </div>
                  <UnorderedList>
                    {aemData.registerModule.passwordToolTip?.passwordTips.map(
                      (tab) => {
                        return (
                          <ListItem size="micro" spacing="compact">
                            {tab}
                          </ListItem>
                        );
                      }
                    )}
                  </UnorderedList>
                  <div style={{ paddingTop: '12px' }}>
                    <Micro size="medium">
                      <span style={{ fontWeight: 'bold' }}>
                        {aemData?.registerModule?.passwordToolTip?.tipTxt}
                      </span>
                      {aemData?.registerModule?.passwordToolTip?.tipDesTxt}
                    </Micro>
                  </div>
                </div>
              </>
            ),
          }}
        />
      </div>
      <div className="captcha-container">
        <ReCAPTCHA
          className="g-recaptcha"
          sitekey={global.config.reCAPTCHASiteKey}
          hl= {isSpanish ? "es" : "en"}
          onChange={verifyToken}
        />
      </div>
    </div>
  );
};

export default RegistrationComponent;
