/** @format */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { TileletGroup } from '@vds/tiles';
import { Line } from '@vds/lines';
import { Body, Title } from '@vds/typography';
import { retrieveFileData } from '../utility/common';
import { useTranslation } from 'react-i18next';
import { TextLink } from '@vds/buttons';
import ProgressStepComponent from './ProgressStepComponent.jsx';
import DOMPurify from 'dompurify';
import './CourseContentComponent.scss';
const CourseContentComponent = (props) => {
  const { t, i18n } = useTranslation();
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  const isIpad = useSelector((state) => state.MediaType.isIpad);
  const articleContent = useSelector((state) => state.Aem.articleContent);
  const aemData = useSelector((state) => state.Aem.registerData);
  const [courseArticleDesc, setArticleCourseDesc] = useState();
  let courseDesc = "";
  const {
    topics,
    topicsLabel,
    courseDescription,
    publishedOnDate,
    resourceType,
    meetTheExpertsLabel,
    resourceTags,
    expertsDetails,
    modules,
    recordedEventTag,
    listOfLoIds
  } = props;
  const isLearningPath = window.location.pathname.indexOf('learningProgram') !== -1 ? true : false
  if(props.courseDescription && props.courseDescription.indexOf('.html') !== -1) {
    retrieveFileData(props.courseDescription)
      .then((jsonData) => {setArticleCourseDesc(jsonData.payload);})
      .catch((error) => {
        console.error(error);
      });
  } else {
    courseDesc = props.courseDescription;
  }
  let presentTopics = topics;
  if (aemData &&
    aemData.registerModule &&
    aemData.registerModule.topics &&
    topics != null) {
    presentTopics = topics.filter(item => aemData.registerModule.topics.filter(topicItem => topicItem.label === item).length > 0);
  }
  
  var allModules = [];
  const lng = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  if (modules && resourceType === t("Course")) {
    allModules = modules.map((element) => {
     let title = element.localizedMetadata.find((item) => item.locale == 'en-US') ? element.localizedMetadata.find((item) => item.locale == 'en-US').name : '';
      if(lng === 'es' && element.localizedMetadata.find((item) => item.locale == 'es-ES')) {
        title = element.localizedMetadata.find((item) => item.locale == 'es-ES').name
      }
      return {
        backgroundColor: 'white',
        showBorder: true,
        title: {
          size: 'bodySmall',
          children: title,
          primitive: 'h5',
        },
        subtitle: {
          size: 'bodySmall',
          children:
            Math.ceil(element.duration / 60) > 0
              ? resourceTags +
                ' ' +
                Math.ceil(element.duration / 60) +
                ' mins'
              : resourceTags,
        },
        descriptiveIcon: {
          name: 'best-practices',
          size: 'small',
        },
      };
    });
  }
  return (
    <>
      {presentTopics && presentTopics != null && presentTopics.length > 0 ? <div className={props.sourceAvilable?"mt-12X mb-6X":"mb-6X"}>
        <Body size="small" color="#000000" bold={true}>
          {t(topicsLabel)}
          <Body
            size="small"
            color="#6F7171"
            primitive={isMobile ? 'div' : 'span'}
            bold={false}
          >
            {presentTopics.map((item, i) => {
              return (
                i < 4 ? (
                isMobile ? <div className={i === 0 ? 'mt-3X' : 'ml-3X mt-3X'} style={{display : 'inline-block'}}>
                  {t(item)}
                </div> : 
                <span className={'ml-3X'}>
                  {t(item)}
                </span>) : ""
              );
            })}
          </Body>
        </Body>
        {isMobile}
      </div> : ''}
      <Body size="large" color="#000000" bold={false}>
        {courseArticleDesc && <div className='desc' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(courseArticleDesc)}} />}
        {courseDesc != "" && <div className='desc' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(courseDesc)}} />}
      </Body>
      <div className='line'>
      {isLearningPath || (!isLearningPath && publishedOnDate || (allModules && allModules != null && allModules.length > 0) || expertsDetails) ? <Line
      type="primary"
      surface="light"
      /> : ''}
      </div>
            {isLearningPath && <div className='mt-6X'>
          <Body size='medium'>
          <b>{t('Note')}:</b> {t('Live Events may rotate in and out of your Learning Path based on schedule and availability. While Events do not technically count toward Path “completion”, we strongly encourage you to take part in Live Events (or watch a recording of a previous session) in order to broaden your full understanding of the Learning Path topic.')}          <br/>
          <br/>
          {t('*All event times are shown in the time zone set by your browser’s location setting . You can manually adjust your time zone in your')} {t('account settings')}          </Body>
      </div>}
      
      <div className='publishedOnDate'>{publishedOnDate}</div>
      <div className='mv-12X'>
        {isLearningPath && <ProgressStepComponent listOfLoIds={listOfLoIds}></ProgressStepComponent>}
      </div>
      {(allModules && allModules != null && resourceType === t("Course") && allModules.length > 0) > 0 ? <div className="mt-12X">
        <Title size="medium" bold={true} color="#000000">
        {recordedEventTag ? t("Event modules") : t("Course modules")}
        </Title>
        <div className="mt-8X sm-mt-6X course-modules">
          <TileletGroup
            data={allModules}
            rowQuantity={!isMobile && !isIpad ? 4 : 2}
            gutterWidth="24px"
            aspectRatio="1:1"
          />
        </div>
      </div>: ''}
      {expertsDetails ? <div className="mt-12X">
        <div tabIndex={0} aria-label={meetTheExpertsLabel}>
        <Title size="medium" bold={true} color="#000000">
          {meetTheExpertsLabel}
        </Title>
        </div>
        <div className="expertsContainer mt-8X">
          <div className="image-section">
            <img src={expertsDetails.profilePhoto} alt="face" />
          </div>
          <div tabIndex={0} aria-label={expertsDetails.about} className="description pl-5X sm-pl-0X sm-mt-6X">
            <Body size="medium" color="#000000" bold={true}>
              {expertsDetails.name} &nbsp;
              <Body size="medium" color="#000000" primitive="span" bold={false}>
                {expertsDetails.about}
              </Body>
            </Body>
          </div>
        </div>
      </div> : ''}
    </>
  );
};
export default CourseContentComponent;