import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { Title } from '@vds/typography';
import { Pagination } from '@vds/pagination';

import { mapperForCourseCardDesktop } from '../../../utility/utils.js'

import CalendarViewCardComponent from '../../common/CalendarViewCards/CalendarViewCards.jsx'
import CourseCardComponent from '../../common/CourseCards/CourseCards.jsx'

import '../CategoryPage.scss';
import { TileContainer } from '@vds/tiles';
import StartCourseComponent from '../../StartCourseComponent.jsx';

const CategoryFilteredCardsComponent = (props) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(props.totalResults/props.numberOfTiles);
  
  const handlePageChange = (pageNumber) => {
    //window.scrollTo({top: document.getElementsByClassName('cards-container')[0].offsetTop, behavior: "smooth"});
    document.scrollingElement.scrollTop = document.getElementsByClassName('cards-container')[0].offsetTop;
    props.handlePagination(pageNumber);
    setPage(pageNumber);
  }

  return (
    <div className=''>
      <div className='cards-container'>
      {!isMobile && (
        <div className='card mb-8X pa-0X'>
          <TileContainer
              padding='0px'
              aspectRatio='3:3.7'
              backgroundColor="white"
              showBorder={false}
              showDropShadow={true}
          >
            <StartCourseComponent
              registerSecTitle={props.firstCardDetails?.registerSecTitle}
              registerSecDesc={props.firstCardDetails?.registerSecDesc}
              registerButtonLabel={props.firstCardDetails?.registerButtonLabel}
              // alreadyMemberLabel={props.firstCardDetails?.alreadyMemberLabel}
              signInLabel={props.firstCardDetails?.signInLabel}
              resourceType={props.firstCardDetails?.resourceHeadingTag}
              needSignInRedirect={true}
            />
          </TileContainer>
        </div>)}
        {!isMobile && (props.detailsData.map(props => {
          let card = <div className='card mb-8X pa-0X'>
            <CourseCardComponent data={mapperForCourseCardDesktop(props)}/>
          </div>;
          return card;
        }))}
        {isMobile && (
          <StartCourseComponent
              registerSecTitle={props.firstCardDetails?.registerSecTitle}
              registerSecDesc={props.firstCardDetails?.registerSecDesc}
              registerButtonLabel={props.firstCardDetails?.registerButtonLabel}
              // alreadyMemberLabel={props.firstCardDetails?.alreadyMemberLabel}
              signInLabel={props.firstCardDetails?.signInLabel}
              resourceType={props.firstCardDetails?.resourceHeadingTag}
              needSignInRedirect={true}
            />
        )}
        {isMobile && (
          props.detailsData.map(props => {
            let card = <div className='card mb-6X pa-0X'> <CalendarViewCardComponent data={props} /></div>;
            return (card);
          })
        )}
      </div>

      <div className='u-flex'>
        {totalPages > 1 && <Pagination
          selectPage={(page) => { handlePageChange(page)}}
          total={totalPages}
          selectedPage={props.resetPagination ? 1 : page}
          showArrow={true}
        /> }
      </div>
    </div>
  );

};

export default CategoryFilteredCardsComponent;

