import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@vds/buttons';
import { Carousel } from '@vds/carousels';
import { Title } from '@vds/typography';

import { pageLoadStarted, userRegistrationClick } from '../common/Analytics/GAWrapper';
import ResourcesCardComponent from '../elements/ResourceCardComponent';
import './EvenMoreResourcesComponent.scss';
import { Col, Grid, Row } from '@vds/grids';


const EvenMoreResourcesComponent = () => {
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  const isIpad = useSelector((state) => state.MediaType.isIpad);
  const aemData = useSelector((state) => state.Aem.data);
  const navigate = useNavigate();

  const handleClick = () => {
    userRegistrationClick('Resources', aemData?.tipsAndResources?.registerCTALink, window.location.host + '/onboarding')
    localStorage.setItem('prevUrl',window.location.href);
    navigate('/onboarding', { replace: true });
   
  //  pageLoadStarted( 'OnBordingPage', window.location.href,selectedTopic?.toString());
  };
  const selectedTopics = useSelector(
    (state) => state.AdobeResources.registerPageSelectedTopics
  );

  return (
    <section aria-label="start-learning" className="resources pv-24X">
      <Grid
        className=""
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}>
        <Row>
          <Col>
            <div aria-label={aemData?.tipsAndResources?.tipAndResourcesTitle} tabIndex={0}>
              <Title primitive="h3" size="2XLarge" bold={true} color="#000000">
                {aemData?.tipsAndResources?.tipAndResourcesTitle}
              </Title>
            </div>
          </Col>
        </Row>
      </Grid>
      <div aria-label="carousel" className="mt-16X sm-mt-8X">
        {aemData &&
          aemData.tipsAndResources &&
          aemData.tipsAndResources.moreResourcesCard && (
            <Carousel
              tabIndex={0}
              viewport={'desktop'}
              peek="standard"
              layout={isMobile ? '1UP' : isIpad ? '2UP' : '3UP'}
              gutter="24px"
              surface="light"
              paginationFill="light"
              paginationInset="12px"
              paginationDisplay="persistent"
              data={aemData?.tipsAndResources?.moreResourcesCard}
              renderItem={(props) => (
                <ResourcesCardComponent {...props}></ResourcesCardComponent>
              )}
            />
          )}
      </div>
      <div aria-label="buttonArea" className="button-area mt-12X sm-mt-8X">
        <Button
          size="large"
          disabled={false}
          use="primary"
          onClick={handleClick}
        >
          {aemData?.tipsAndResources?.registerCTALink}
        </Button>
      </div>

    </section>
  );
};
export default EvenMoreResourcesComponent;
