import React from 'react';
import './Results.scss';
import Header from './Header.jsx';
import UpcomingEvents from './UpcomingEvents.jsx';
import SuggestedCourses from './SuggestedCourses.jsx';
import QuickTips from './QuickTips.jsx';
import OffersComponent from './Offers.jsx'
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Grid, Row } from '@vds/grids';
import { Title } from '@vds/typography';
import { Button, ButtonGroup } from '@vds/buttons';
import { useNavigate } from 'react-router-dom';
import { updateRegisterTriggeredFrom, updateSelectedCourse } from '../../store/aem.js';
import store from '../../store/store.js';
import ResultsLearningPath from './LearningPath/resultsLearningPath.jsx';
import { useTranslation } from 'react-i18next';
const Results = () => {
  // render() {
    const { t, i18n } = useTranslation();
    const navigate=useNavigate()
    const allEventsWithTimeZone = useSelector((state) => state.AdobeResources.allEventsWithTimeZone);
    const allCourses = useSelector((state) => state.AdobeResources.allCourses);
    let { topicType } = useParams();
    const aemData = useSelector((state) => state.Aem.data);
    const aemDataResults=aemData?.results;
    const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
    useEffect(() => {
      //window.scrollTo({ top: 0, behavior: 'smooth' });
  },[]);
    return (<div className="app-results-page bg-white">
      <Header topic={topicType}/>
      <div className='notification-ribbon'>
      <Grid>
      <Row>
        <Col
          colSizes={{
            mobile: 4,
            tablet: 12,
            desktop: 12,
          }}
        >
        <div className='ribbon-container pv-6X'>
        <Title size='small' primitive='h1'>
       <b> {aemDataResults?.regnow}</b> {aemDataResults?.toaccess}
        </Title>
        <div>
        <ButtonGroup
            childWidth={'100%'}
            viewport={'desktop'}
            rowQuantity={{ desktop: 2 }}
            data={[
              {
                children:aemDataResults?.register,
                size: 'large',
                use: 'primary',
                width: 'auto',
                onClick: ()=>{store.dispatch(updateRegisterTriggeredFrom('resultsPage'));store.dispatch(updateSelectedCourse(topicType));navigate("/onboarding")},
              },
              {
                children: t('Sign in'),
                size: 'large',
                use: 'textLink',
                width: 'auto',
                onClick: () => 
                  {location.href=global.config.CIAM_SIGN_IN_URL+encodeURI(`${global.config.AUTHENTICATED_URL_BASE_PATH}results/${topicType}?ciamLang=${userLanguage}`)
                   document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`
              },
              },
             
            ]}
            alignment={'center'}
          />
        {/*<Button onClick={e=>{store.dispatch(updateRegisterTriggeredFrom('resultsPage'));store.dispatch(updateSelectedCourse(topicType));navigate("/onboarding")}}>
        {aemDataResults?.register}
        </Button>*/}
        </div>
        
        </div>
        </Col>
        </Row>
        </Grid>
      </div>
      <ResultsLearningPath  bgGray={false} topic={topicType} />
      {allEventsWithTimeZone&&<UpcomingEvents topic={topicType} bgGray={true}/>}
      {allCourses&&<SuggestedCourses topic={topicType}  bgGray={false}/>}
      <QuickTips topic={topicType}  bgGray={true}/>
      <OffersComponent bg={'bg-white'} topic={topicType}/>
      {/* <OffersComponent/> */}
    </div>
    );
  //}
}

export default Results;