import React , { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { Accordion, AccordionItem, AccordionHeader, AccordionDetail } from '@vds/accordions';
import { ButtonGroup, TextLink } from '@vds/buttons';
import { CheckboxGroup } from '@vds/checkboxes';
import { DatePicker } from '@vds/date-pickers';
import { Line } from '@vds/lines';
import { RadioButtonGroup } from '@vds/radio-buttons';
import { Body, Title } from '@vds/typography';

import '../CategoryPage.scss';
import { Modal, ModalBody, ModalTitle } from '@vds/modals';

const CategoryFiltersComponent = ({filters, handleFilter, headingData, handleRemoveAllFilter, setShowMobileFilter}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [filterParameters, setFilterParameters] = useState(filters);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date().addDays(28));
    const [dateRange, setDateRange] = useState();
    const history = useNavigate();


    console.log("headingData", headingData);

    const createMapForSelectedObject = (other, selected) => {
      return {selected: selected, children: other.children, value: other.value};
    }

    const handleStartDateChange = (selectedDate) => {
      setStartDate(new Date(selectedDate));
      filterParameters.filter(e => e.category == "Dates")[0].startDate = new Date(selectedDate);
    }

    const handleEndDateChange = (selectedDate) => {
      setEndDate(new Date(selectedDate));
      filterParameters.filter(e => e.category == "Dates")[0].endDate = new Date(selectedDate);
    }

    const handleDateRadioChange = (radioSelect) => {
      if(radioSelect == "viewAll"){
        setDateRange(false);
        delete filterParameters.filter(e => e.category == "Dates")[0].startDate;
        delete filterParameters.filter(e => e.category == "Dates")[0].endDate;
      }
      if(radioSelect == "dateRange"){
        setDateRange(true);
        filterParameters.filter(e => e.category == "Dates")[0].startDate = startDate;
        filterParameters.filter(e => e.category == "Dates")[0].endDate = endDate;
      }
    }

    const handleViewAll = (value, item) => {
      let viewAllItem = {};
      let otherItems = [];
      
      if(item.category != "Dates") { //Remove this for vsbdr ui
        if(value == "View all") {
          viewAllItem = item.subCategory[0].options.filter(item => item.value == "View all")[0];
          otherItems = item.subCategory[0].options.filter(item => item.value != "View all");
          if(viewAllItem) {
            if(viewAllItem.selected) {
              filterParameters.forEach((e) => {
                if(e.category == item.category) {
                  e.subCategory[0].options.map(e => e.selected = true);
                }
              });
            } else {
              filterParameters.forEach((e) => {
                if(e.category == item.category) {
                  e.subCategory[0].options.map(e => e.selected = false);
                }
              });
            }
          }
        } else {
          console.log("value", value);
          console.log("before filterParameters", filterParameters);
          filterParameters.forEach((e) => {
            if(e.category == item.category) {
              e.subCategory[0].options.map(e => e.value == "View all" ? e.selected = false : e);
              e.subCategory[0].options.map(e => e.value == value ? createMapForSelectedObject(e, !e.selected) : e);
            }
          });
          console.log("filterParameters", filterParameters);
        }
      }
      //   if(value == "View all") {
      //     viewAllItem = item.subCategory[0].options.filter(item => item.value == "View all")[0];
      //     otherItems = item.subCategory[0].options.filter(item => item.value != "View all");
      //     if(viewAllItem) {
      //       if(viewAllItem.selected) {
      //         otherItems = otherItems.map(other => createMapForSelectedObject(other, true));
      //       } else {
      //         otherItems = otherItems.map(other => createMapForSelectedObject(other, false));
      //       }
      //     }
      //   } else {
      //     viewAllItem = item.subCategory[0].options.filter(item => item.value == "View all")[0];
      //     otherItems = item.subCategory[0].options.filter(item => item.value != "View all");
      //     console.log("otherItems", otherItems);
      //     viewAllItem.selected = false;
      //   }
      //   let filterParametersTemp = [];
      //   filterParameters.forEach((e) => {
      //     if(e.category == item.category) {
      //       e.subCategory[0].options = [];
      //       e.subCategory[0].options.push(viewAllItem);
      //       otherItems.forEach(other => {e.subCategory[0].options.push(other)});
      //     }
      //     filterParametersTemp.push(e);
      //   });
      //   setFilterParameters(filterParametersTemp);
      // }
    }

    const handleViewResults = () => {
      filterParameters.forEach((filter) => {
        let index = 0;
        if(filter.category !== 'Dates'){
        const isAllDeselcted = filter.subCategory[index].options.filter(item => item.selected == true);
        if(isAllDeselcted.length === 0){
          filter.subCategory[index].options.map(e => {
            if(e.value == "View all") e.selected = true});
        }
        if(filter.category === 'Topic' && isAllDeselcted.length === 0) {
          filter.subCategory[index].options.map(e => {
            if(e.value == "View all") e.skipToShow = false});
        }
      }
      });
      handleFilter(filterParameters);

      if(isMobile)
        setShowMobileFilter(false);
    }

    return (
        <div className='filters-container'>
            {!isMobile && <Title size='medium' bold={true}>
                {t('Filter')}
            </Title>}
            <div className='mt-12X'>
                <Line type='secondary'></Line>
            </div>
            <Accordion
                    surface="light"
                    topLine={false}
                    bottomLine={true}
                    type="multi"
            >
                {filterParameters && filterParameters.map((item, n) => {
                return (
                <AccordionItem key={n} opened={item.category === 'Topic' && !isMobile ? true:false}>
                    <AccordionHeader
                            trigger={{ type: "icon", alignment: "top" }}
                    >
                    {t(item.category)}
                    </AccordionHeader>
                    <AccordionDetail>
                        {item.type === 'dateRange' && (<div>
                        <RadioButtonGroup
                                onChange={(e) => { handleDateRadioChange(e.target.value) }}
                        error={false}
                        data={[
                        {
                        name: 'group',
                        children: t('View All'),
                        value: 'viewAll',
                        ariaLabel: t('View All'),
                        disabled: false
                        },
                        {
                        name: 'group',
                        children: t('Select By Date Range'),
                        value: 'dateRange',
                        ariaLabel: t('Select By Date Range'),
                        }
                        ]}
                        />
                        {dateRange && <div className='mt-6X'>
                          <DatePicker
                            type="calendar"
                            size="large"
                            surface="light"
                            label={t("Start date")}
                            dateFormat="MM/DD/YYYY"
                            maxDate={endDate}
                            selectedDate={item.startDate ? item.startDate : startDate}
                            onChange={e => handleStartDateChange(e)}
                          />
                        </div>}
                        {dateRange && <div className='mt-6X'>
                          <DatePicker
                            type="calendar"
                            size="large"
                            surface="light"
                            label={t("End date")}
                            dateFormat="MM/DD/YYYY"
                            minDate={startDate}
                            selectedDate={item.endDate ? item.endDate : endDate}
                            onChange={e => handleEndDateChange(e)}
                          />
                        </div>}


                    </div>)}
                        {item.type === 'checkbox' && item.subCategory?.map((subItem, n) => {
                        return (<div key={n} className='mb-6X'>
                        <div className='mb-6X u-flex-left'><Body size='large' bold={true}>{t(subItem.name)}</Body> <span className='ml-2X'>
                              {subItem.edit ? (<TextLink
                                type="standAlone"
                                size='small'
                                onClick={(e) => history(subItem.editLink)}>
                                {t(edit)}
                            </TextLink>) : ''}
                            </span> </div>
                        <CheckboxGroup data={subItem.options} onChange={(e) => handleViewAll(e.target.value, item)}/>
                    </div>);
                        })}
                        <div className='mv-6X'>
                            <Body size='medium' primitive='span'>
                            {t(item?.text?.text)}
                            </Body>
                            {headingData?.modalContent && <Modal 
                              toggleButton={<TextLink
                                    type="standAlone"
                                    tabIndex="-1"
                                    aria-hidden="true"
                                size='small'>
                            {t(item.text?.clickLabel)}
                                </TextLink>} 
                              surface="light"
                              fullScreenDialog={false}
                              disableAnimation={false}
                              disableOutsideClick={false}
                              ariaLabel={t("Testing Modal")}
                            >
                              <ModalTitle>
                                {t(headingData?.textLink?.label)}
                              </ModalTitle>
                              <ModalBody>
                                <div className='text-block-container'>
                                  {headingData.modalContent?.map(item=>{
                                    let _HTML=<div className="text-blocks">
                                        <div>
                                          <Title size='small' bold>{t(item.label)}</Title>
                                        </div>
                                        <div>
                                          <Body size="small">
                                          {t(item.text)}
                                          </Body>
                                        </div>
                                    </div>;
                                    return _HTML;
                                  })}
                                </div>
                              </ModalBody>
                            </Modal>}
                        </div>
                    </AccordionDetail>
                </AccordionItem>
                );
                })}
            </Accordion>
            <div className='mt-12X'>
                <ButtonGroup
                        childWidth={'100%'}
                        viewport={'desktop'}
                        rowQuantity={{ desktop: 2 }}
                        data={[
                        {
                        children: t('View Results'),
                size: 'small',
                use: 'primary',
                width: 'auto',
                onClick: (e) => handleViewResults(),
                },
                {
                ariaLabel:t('Clear All'),
                role:"button",
                children: t('Clear All'),
                size: 'small',
                use: 'secondary',
                width: 'auto',
                onClick: () => handleRemoveAllFilter(),
                },

                ]}
                alignment={'center'}
                />

            </div>
        </div>
    );

};

export default CategoryFiltersComponent;

