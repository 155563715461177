import React from 'react';
import './Header.scss';
import CategoryHeadersComponent from './categoryHeader.jsx';
import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
//import { topicMap,topicIcon,topicSubtext,topicMapSpanish } from './questionsMock.js';
import { pageLoadCompleted, pageLoadStarted } from '../../common/Analytics/GAWrapper.jsx';
import { useSelector} from 'react-redux'
import { useEffect } from 'react';
import { LabelsFormat } from '../../utility/utils.js';
const Header = (props) => {
  const aemData = useSelector((state) => state.Aem.data);
 
  const aemDataResults=aemData?.results;
  //console.log("Props",topicmap.get(props.topic))
  let isSpanish = localStorage.getItem("lng")==='es' ? true : false;
  //const topicmap=isSpanish?topicMapSpanish:topicMap;
  const aemtopic=aemData?.topicCard?.topicMap
  const aemtopicIcon=aemData?.topicCard?.topicIcon
  const aemtopicSubTitle=aemData?.topicCard?.topicSubtitle.filter((item)=>item[0]===props.topic)[0][1]
 console.log("aemtopicSubTitle===",aemtopicSubTitle)
  
  const topicsIconDisplay=aemtopicIcon.filter((item)=>item[0]===props.topic)
  const topicsDisplay=aemtopic.filter((item)=>item[0]===props.topic)
 
  let pageData = {
    results: {
     // label: 'Cyberscurity',
      label: topicsDisplay?.[0]?.[1],
      subText:  aemtopicSubTitle,
      textLink: { label: '', link: '', tooltipText: '' },
      buttons: [],
      sideTextLink: { label: '', link: '' },
      sideIcon: { name: topicsIconDisplay?.[0]?.[1], color: '#000000', size: '78' }
    },

  };
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );
  useEffect(() => {
    
    //window.scrollTo({top: 0});
    console.log("location",localStorage.getItem('prevUrl'))
    document.title = `Verizon Small Business Digital Ready ${topicsDisplay?.[0]?.[1]}`

    pageLoadStarted("Results",window.location.origin + localStorage.getItem('prevUrl'),selectedTopics?.toString());
    pageLoadCompleted();
    localStorage.setItem('prevUrl',window.location.href);
  },[]);

  return (
    <div className='result-header-wrapper'>
      <div className='result-bread-crumb'>
        <Grid>
          <Row>
            <Col
              colSizes={{
                mobile: 4,
                tablet: 12,
                desktop: 12,
              }}
            >
              <div className='pv-8X'>
                <Breadcrumbs
                  surface="light">
                  <BreadcrumbItem
                    href="/">{aemDataResults?.homeTxt}
                  </BreadcrumbItem>
                  {/*<BreadcrumbItem
                  >  {aemDataResults?.learning}
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    >
                   {aemDataResults?.learnByTopic}
            </BreadcrumbItem>*/}
                  <BreadcrumbItem
                    
                    selected={true}>
                  {LabelsFormat(topicsDisplay?.[0]?.[1])}
                  </BreadcrumbItem>
                </Breadcrumbs>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              colSizes={{
                mobile: 4,
                tablet: 12,
                desktop: 12,
              }}
            >
              <CategoryHeadersComponent props={pageData.results} bg={''} />

            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
};
export default Header;