// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container {
  max-width: 387px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/views/onboarding/RegistrationFlowComponent/RegistrationHeaderComponent/RegistrationHeaderComponent.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF","sourcesContent":[".title-container {\n  max-width: 387px;\n  width: 100%;\n}\n// .jHYqYp {\n//   font-size: 3rem !important;\n//   font-weight: 700 !important;\n//   line-height: 3rem !important;\n//   font-family: Verizon-NHG-eDS,Helvetica,Arial,Sans-serif;\n//   color: #000000;\n//   margin: 0;\n//   text-decoration: none;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
