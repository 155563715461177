import { useSelector } from 'react-redux';

import './PartnersComponent.scss';
import { Title } from '@vds/typography';
import { Col, Grid, Row } from '@vds/grids';


const PartnersComponent = () => {
  const aemData = useSelector((state) => state.Aem.data);
  return (
    <>

      {aemData?.partnersSection && (
        <div className="logos-main pv-24X">
          <Grid
            className=""
            bleed="1440"
            colSizes={{
              mobile: 4,
              tablet: 12,
              desktop: 12,
            }}>
            <Row>
              <Col>
                <div className="logos-title mb-8X" tabIndex={0}>
                  <Title
                    size="medium"
                    bold={true}
                    color="#000000"
                  >
                    {aemData?.partnersSection?.title}
                  </Title>
                </div>
                <div className="logos">
                  {
                    aemData?.partnersSection?.logos.map((logo, index) => {

                      return (
                        <div key={index} className="logo" aria-label={logo.label} tabIndex={0}>
                          <img alt="alt image" width="136px" src={logo.url} />
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </Grid>
        </div>

      )}

    </>
  );
};

export default PartnersComponent;
