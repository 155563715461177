import { Badge } from '@vds/badges';
import { ButtonIcon } from '@vds/button-icons';
import { Icon } from '@vds/icons';
import { TileContainer } from '@vds/tiles';
import { Body, Micro, Title } from '@vds/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import './LearningPath.scss';

const LearningPathCard = (props) => {

  const { t, i18n } = useTranslation();
  const history=useNavigate()
  const handleClick = () => {  
    window.scrollTo(0, 0);
    history(`/learning-details/${props.data.courseId}`)
  }
  const getTimeInLanguage = (time) => {
    time = time?.replace('minutes remaining', t('minutes remaining'));
    time = time?.replace('minutes', t('minutes'));
    return time;
  };

  return (
    <TileContainer
      ariaLabel={props.data?.ariaLabel?.replace("undefined", "") + props?.data?.label}
      padding='40px'
      aspectRatio='1127:318'
      backgroundColor="white"
      showBorder={false}
      showDropShadow={true}
      tabIndex={-1}
      onClick={(e) => handleClick()}
    >
      <div className="learningPathCard">
        <div className='image-icons'>
          {props?.data?.tag &&
            <Badge
              fillColor="white">{t(props?.data?.tag)}
            </Badge>
          }
          {props?.data?.showBookmarkIcon &&
            <span className='ml-auto' role='button'>
              <ButtonIcon
                kind="lowContrast"
                size="small"
                surfaceType="media"
                renderIcon={(props) => <Icon name="bookmark" {...props} />}
              />
            </span>
          }
        </div>
        <div className="image">
          <img src={props?.data?.desktopImage} alt={props?.data?.label} />
        </div>
        
        <div className="description pl-8X sm-pa-4X">
        {props.data.shortDesc && (
          <div className='mb-8X mt-2X sm-mb-2X'>
            <Body size='small' bold>
              <span className='bg-gray pa-2X ph-3X ideas' >
                <Icon name='ideas-solutions'></Icon>
                {props.data.shortDesc?.slice(0, 72)}
              </span>
            </Body>

          </div>
        )}
          
          <div className='label mb-3X sm-mb-2X'>
            <Micro>
              <div className='course-label' aria-label={props?.data?.resourceType}>
                <b>{t(props?.data?.format)}</b>
                {props?.data?.resourceType &&
                  <span className='dot-wrap mh-2X'></span>}
                <span className=''>{props?.data?.resourceType?.split(',').map(e=> t(e.trim())).join(', ')}</span>
              </div>
            </Micro>
          </div>
          <div className="title-label label mb-3X sm-mb-2X" aria-label={props?.data?.label}>
            <Title bold={false} color='#000000' size='XLarge'> {props?.data?.label?.slice(0, 72)}</Title>
          </div>
          <div className="durations mt-auto">

            <div className="duration-text" aria-label={props?.data?.dateAndTimeValue} >
              {props?.data?.duration?.preTextIcon && <Icon
                name={props?.data?.duration?.preTextIcon ? props?.data?.duration?.preTextIcon : "calendar"}
                size="small"
                color={props?.data?.duration?.color}
              />}
              {props.data?.duration?.preText && (<Micro
                size="small"
                color={props?.data?.duration?.color}
                bold={true}
                primitive="span">
                <span className='mr-2X'> {t(props?.data?.duration?.preText)}</span>
              </Micro>)}
              {(props?.data?.duration?.icon || props?.data?.dateAndTimeValue) && (props?.data?.duration?.icon != "" || props?.data?.dateAndTimeValue !== '') && <Icon
                name={props?.data?.duration?.icon ? props?.data?.duration?.icon : "count-down"}
                size="small"
                color={props?.data?.duration?.color}
              />}
              {props.data?.dateAndTimeValue && (<Micro
                size="small"
                color={props?.data?.duration?.color}
                bold={true}
                primitive="span">
                <span className='mr-2X'> {t(getTimeInLanguage(props?.data?.dateAndTimeValue))}</span>
              </Micro>)}
              <Micro
                size="small"
                color={props?.data?.duration?.color}
                bold={true}
                primitive="span">
                <span className="ml-2X">{t(getTimeInLanguage(props?.data?.duration?.time))}</span>
              </Micro>
              {props.data?.duration?.extLink && (
                <TextLink
                  type='inline'
                  size='small'
                  onClick={e => window.open(props?.data?.duration?.extLink?.link, '_blank').focus()}>
                  <span className="ml-2X" style={{ color: props?.data?.duration?.color }}>{t(props?.data?.duration?.extLink?.text)}</span>
                </TextLink>)}
              {props.data?.inProgress && (
                <TextLink
                  type='inline'
                  size='small'
                  onClick={e => { callJoinNow(props.data); }}>
                  <span className="ml-2X" style={{ color: props?.data?.duration?.color }}>{t(props?.data?.linkText)}</span>
                </TextLink>)}
            </div>

            <div>
              <ButtonIcon
                //tabIndex={0}
                kind="ghost"
                size="medium"
                ariaLabel={`${props?.data?.ariaLabel ? props?.data?.ariaLabel?.replace('undefined', '') : ""} ${props?.data?.label} ${t(props?.data?.format)}`}
                role='link'
                renderIcon={(props) => <Icon name="right-arrow" {...props} />}
                onClick={e=>handleClick()}
              />
            </div>
          </div>

        </div>

      </div>

    </TileContainer>
  );
};

export default LearningPathCard;