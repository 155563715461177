import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Title } from '@vds/typography';

import { userRegistrationStep,pageLoadStarted,pageLoadCompleted } from '../../../../common/Analytics/GAWrapper';
import './RegistrationHeaderComponent.scss';

const RegistrationHeaderComponent = (props) => {
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  const selectedTopics = useSelector(
    (state) => state.Aem.registrationStepsData.businessData.topics
  );

  const { currentStep, aemData } = props;
  const [titles, setTitles] = useState({});

  useEffect(() => {
    let url = '';
    let prevUrl = '';
    console.log("Language",localStorage.getItem("lng"))
   
    switch (currentStep) {
      case 1:
        setTitles({
          title: aemData?.registerModule?.registerTitle1,
          subtitle: aemData?.registerModule?.registersubTitle1,
        });
        userRegistrationStep(
          aemData?.registerModule?.registerTitle1,
          currentStep,
          selectedTopics
        );
        // url = localStorage?.getItem('url');
        // localStorage.setItem('prevUrl', url);
        // localStorage.setItem('url', path);
        if(localStorage?.getItem('prevUrl')?.startsWith('/'))
          {
        pageLoadStarted("Hi. Let’s find the topics that interest you.",window.location.origin+localStorage.getItem('prevUrl'),selectedTopics?.toString());
          }
          else{
            pageLoadStarted("Hi. Let’s find the topics that interest you.",localStorage.getItem('prevUrl'),selectedTopics?.toString());
          }
        pageLoadCompleted();
        break;
      case 2:
        setTitles({
          title: aemData?.registerModule?.registerTitle2,
          subtitle: aemData?.registerModule?.registersubTitle2,
        });
        console.log('title2');
        userRegistrationStep(
          aemData?.registerModule?.registerTitle2,
          currentStep,
          selectedTopics
        );
        pageLoadStarted("Do you identify with any of the following groups?",localStorage.getItem('prevUrl'),selectedTopics?.toString());
        //pageLoadStarted("Do you identify with any of the following groups?",window.location.host + '/onboarding',selectedTopics?.toString());
        pageLoadCompleted();
        break;
      case 3:
        setTitles({
          title: aemData?.registerModule?.registerTitle3,
          subtitle: aemData?.registerModule?.registersubTitle3,
        });
        console.log('title3');
        userRegistrationStep(
          aemData?.registerModule?.registerTitle3,
          currentStep,
          selectedTopics
        );
       // pageLoadStarted("And where’s your business located?",window.location.host + '/onboarding',selectedTopics?.toString());
        pageLoadStarted("And where’s your business located?",localStorage.getItem('prevUrl'),selectedTopics?.toString());
        pageLoadCompleted();
        break;
      case 4:
        setTitles({
          title: aemData?.registerModule?.registerTitle4,
          subtitle: '',
        });
        console.log('title4');
        userRegistrationStep(
          aemData?.registerModule?.registerTitle4,
          currentStep,
          selectedTopics
        );
        pageLoadStarted("Excellent. To finish registering, enter an email and password.",localStorage.getItem('prevUrl'),selectedTopics?.toString());
        //pageLoadStarted("Excellent. To finish registering, enter an email and password.",window.location.host + '/onboarding',selectedTopics?.toString());
        pageLoadCompleted();
        break;
      default:
        setTitles({
          title: '',
          subtitle: '',
        });
    }
  }, [currentStep, aemData, setTitles, userRegistrationStep]);

  return (
    <>
      <div
        tabIndex="0" aria-label={titles.title}
        className={`title-container ${titles.subtitle !== '' ? 'mb-3X' : ''}`}
      >
        <Title primitive="h1" size={isMobile ? 'XLarge' : 'large'} bold={true} color="#000000">
          {titles.title}
        </Title>
      </div>
      {titles.subtitle ? <div className="widthfix" tabIndex="0" aria-label={titles.subtitle}>
        <Title size={isMobile ? 'medium' : 'small'} bold={false}>
          {titles.subtitle}
        </Title>
      </div> : <div className="widthfix">
        <Title size={isMobile ? 'medium' : 'small'} bold={false}>
          {titles.subtitle}
        </Title>
      </div>}
      
    </>
  );
};

export default RegistrationHeaderComponent;
