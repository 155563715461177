import React from 'react';
import CalendarViewCardComponent from './common/CalendarViewCards/CalendarViewCards.jsx';
import { createEventObject, createCourseObject } from '../utility/utils.js';
import { Body, Title } from '@vds/typography';
import { DropdownSelect } from '@vds/selects';
import { colors } from '@material-ui/core';
import { Icon } from '@vds/icons';
import { isMobile } from 'react-device-detect';
import { TextLink } from '@vds/buttons';
import { useTranslation } from 'react-i18next';

const ProgressStepComponent = (props) => {
  const { listOfLoIds } = props
  const { t, i18n } = useTranslation();
  var langType = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  var loArray = []
  
  loArray = listOfLoIds.map((item) => {
    let resourceHeadingTag;
    let details = [];
    switch (item.format) {
      case "Virtual Classroom":
      case "Blended":
        resourceHeadingTag = 'event';
        break;
      case "Classroom":
        resourceHeadingTag = 'event';
        break;
      case "Self Paced":
        resourceHeadingTag = 'course';
        break;
    }
    details.push(resourceHeadingTag == 'course' ? createCourseObject(item, langType) : createEventObject(item, langType))
    return details;
  })
  return (
    <>
      {loArray.map((item, index) => {
        let _html = <div className='vertical-stepper'>
          <div className='step'>
            <div className={'progress-icon'}>
              {/* <Icon name={item[0].icon?.name} size={isMobile ? 'small' : 'medium'} color={item[0].icon?.color} /> */}

            </div>
            <div className='step-line'>

              <div className='content pl-8X pb-8X sm-pl-5X sm-pb-5X'>
                {/* {item?.datesAvilable?.length && <div className='mw200 pb-2X mb-2X'>
                  <DropdownSelect
                    label="Multiple Dates Available"
                    errorText='Select a state'
                    error={false}
                    disabled={false}
                    readOnly={false}
                    inlineLabel={false}
                  >
                    {item?.datesAvilable?.map((date, index) => {
                      return <option key={index} value={date.date} label={date.date} />;
                    })}
                  </DropdownSelect>

                </div>} */}
                <div className='pb-2X format-name'>
                  <Body size='medium' bold>
                  {t(item[0].format)}
                  </Body>
                </div>

                <div className='mb-6X mt-0X pa-0X box-shadow'>
                  <CalendarViewCardComponent data={item[0]} />
                  {/* {item?.recording?.avilable && <div className='recording pb-2X'>
                    <div>
                      <Body size='small' bold primitive='span'>
                        <span className='mr-1X'>
                          <Icon name='history'></Icon>
                        </span>
                        Can’t attend the live event? We have a recording available from a previous session.

                      </Body>
                    </div>
                    <TextLink size='small' bold={false}>Watch Recording</TextLink>
                  </div>} */}

                </div>
              </div>
            </div>
          </div>
        </div>;
        return _html;
      })}
      <div className='vertical-stepper'>
        <div className='step'>
          <div className='progress-icon green-border'>
            <Icon name='award' size={isMobile ? 'small' : 'medium'} color='#008331' />
          </div>
          <div className='step-line'>
            <div className='content pl-8X'>
              <Title size='medium'>{t("Complete learning path.")}</Title>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressStepComponent;