import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@vds/buttons';
import { Title } from '@vds/typography';
import { Col, Grid, Row } from '@vds/grids';


import './ValuePropositionComponent.scss';
import { userRegistrationClick, pageLoadStarted } from '../common/Analytics/GAWrapper';
const ValuePropositionComponent = () => {
  const aemData = useSelector((state) => state.Aem.data);
  const navigate = useNavigate();
  
  const handleClick = () => {

    localStorage.setItem('prevUrl',window.location.href);
  navigate('/onboarding', { replace: true })
    userRegistrationClick('', 'Register now', window.location.host + '/onboarding')
    //pageLoadStarted( 'OnboardingPage', window.location.href,selectedTags?.toString());

  };
  return (
    <section aria-label="for-you" className="value-proposition pt-32X pb-32X">


      <Grid
        className=""
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}>
        <Row>
          <Col>
            <div tabIndex={0}>
              <Title size="2XLarge" bold={true} color="#000000">
                {aemData?.valueProposition?.title}
              </Title>
            </div>
            <div className="mt-8X" tabIndex={0}>
              <Title size="medium" color="#000000">
                {aemData?.valueProposition?.heroFactTxt2}
              </Title>
            </div>
            <div
              className="button-area mt-8X"
              style={{ display: 'flex', justifyContent: 'center' }}
              tabIndex={0}
            >
              <Button disabled={false} use="primary" onClick={handleClick}>
              {aemData?.offerandIncentives?.registerCTALink}
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
    </section>
  );
};

export default ValuePropositionComponent;
