import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Grid, Row } from '@vds/grids';
import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { ButtonGroup, TextLink, TextLinkCaret } from '@vds/buttons';
import { Body, Title } from '@vds/typography';
import { Tooltip } from '@vds/tooltips';
import { Icon } from '@vds/icons';
import { useNavigate } from "react-router-dom";

import '../CategoryPage.scss';
import { Modal, ModalBody, ModalTitle } from '@vds/modals';

const CategoryHeadingComponent = ({ props }) => {
  console.log("props==== header ",props)
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [formatModal, setFormatModal] = useState(false);

  /* if (!coursesDetails) return <div>Loading...</div>; */

  return (
    <div className='sm-pt-0X pv-8X u-flex-apart pb-16X sm-pb-12X m-flex-col'>
      <div className='maxw-808'>
        {(props.sideIcon?.name && isMobile) && (
          <div className='sm-mb-4X'>
            <Icon name={props.sideIcon?.name} size={props.sideIcon?.size} color={props.sideIcon?.color} />
          </div>
        )}
        <Title bold={true} size='2XLarge'>{t(props.label)}</Title>
        <div className='mt-4X'>
          {props.subText && (
            <Title size='medium'> {t(props.subText)} </Title>)}
          {props.textLink?.label && (<div className='mt-4X u-flex-left'>
          {props.modalContent && <Modal 
            toggleButton={<TextLink type="standAlone" role ="button" ariaLabel={t(props.textLink?.label)}> {t(props.textLink?.label)} </TextLink>} 
            surface="light"
            fullScreenDialog={false}
            disableAnimation={false}
            disableOutsideClick={false}
            ariaLabel="Testing Modal"
          >
            <ModalTitle>
              {t(props.textLink?.label)}
            </ModalTitle>
            <ModalBody>
              <div className='text-block-container'>
                {props.modalContent?.map(item=>{
                  let _HTML=<div className="text-blocks">
                      <div>
                        <Title size='small' bold>{t(item.label)}</Title>
                      </div>
                      <div>
                        <Body size="small">
                        {t(item.text)}
                        </Body>
                      </div>
                  </div>;
                  return _HTML;
                })}
              </div>
            </ModalBody>
          </Modal>}
            {/* {!!props.textLink?.tooltipText && (
              <Tooltip title={props.toolTipHeading} size="medium" surface="light">
                {props.toolTipHeading}
              </Tooltip>
            )} */}
          </div>)}
          {props.buttons && (
            <div className='cta-area mt-8X'>
              <ButtonGroup childWidth={'100%'} viewport={'desktop'} rowQuantity={{ desktop: 2 }} data={props.buttons} alignment={'left'} />
            </div>
          )}
        </div>
      </div>
      <div>
        {props.sideTextLink?.label && (
          <TextLinkCaret surface="light" disabled={false} iconPosition="right" onClick={(e) => navigate(props.sideTextLink?.link)}> {props.sideTextLink?.label}</TextLinkCaret>
        )}
        {(props.sideIcon?.name && !isMobile) && (
          <Icon name={props.sideIcon?.name} size={props.sideIcon?.size} color={props.sideIcon?.color} />
        )}
      </div>
    </div>
  );

};

export default CategoryHeadingComponent;

