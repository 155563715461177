import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from '../../components/React-i18Next/Language-JSON/English.json';
import Spanish from '../../components/React-i18Next/Language-JSON/Spanish.json';

const resources = {
  en: {
    translation: English
  },
  es: {
    translation: Spanish
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: localStorage.getItem("lng") || "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;