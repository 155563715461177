import { useEffect, useState } from 'react';
import Header from '../layout/Header';
import ContactUSComponent from '../components/ContactUSComponent';
import CourseFilterComponent from '../components/CourseFilterComponent';
import EvenMoreResourcesComponent from '../components/EvenMoreResourcesComponent';
import GrantsComponent from '../components/GrantsComponent';
import PartnersComponent from '../components/PartnersComponent';
import SocialProofComponent from '../components/SocialProofComponent';
import TipsAndResourcesComponent from '../components/TipsAndResourcesComponent';
import ValuePropositionComponent from '../components/ValuePropositionComponent';
import WelcomeComponent from '../components/WelcomeComponent';
import './LandingPage.scss';
import Footer from '../layout/Footer';
import QuickTips from '../components/QuickTips/QuickTips.jsx';
import { useSelector } from 'react-redux';

const LandingPage = () => {

  let aemQuickTips = useSelector((state) => state.Aem.quickTipData);
  useEffect(()=>{
    localStorage.setItem('categoryPath',location.pathname);
  },[]);
  return (
    <main className="landing-container text-center">
      <Header />
      <WelcomeComponent />
      {/* <QuickTips /> */}
      {aemQuickTips && <TipsAndResourcesComponent /> }
      
      <CourseFilterComponent />
      <EvenMoreResourcesComponent />
      <GrantsComponent />
      <SocialProofComponent />
      <ValuePropositionComponent />
      <PartnersComponent />
      <ContactUSComponent />
    </main>
  );
};

export default LandingPage;
