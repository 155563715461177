import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@vds/buttons';
import { Title } from '@vds/typography';

//import { pageLoadStarted } from '../common/Analytics/GAWrapper';
import TagBasedCoursesComponent from '../elements/TagBasedCoursesComponent';
import TagSelectionComponent from '../elements/TagSelectionComponent';
import { updateRegisterPageSelectedTopics } from '../store/adoberesources';
import store from '../store/store';
import { pageLoadStarted, userRegistrationClick } from "../common/Analytics/GAWrapper";
import './CourseFilterComponent.scss';
import { Col, Grid, Row } from '@vds/grids';
import { useTranslation } from 'react-i18next';

const CourseFilterComponent = () => {
  const { t, i18n } = useTranslation();
  const spanishData = useSelector((state) => state.Aem.spanishLang);
  const engData = useSelector((state) => state.Aem.registerData);
  let isSpanish = localStorage.getItem("lng")==='es' ? true : false;
  const [aemData, setAemData] = useState(!isSpanish ? engData : spanishData);
  const [enableFocus,setEnableFocus]=useState(true);
  useEffect(() => {
    setAemData(!isSpanish ? engData : spanishData);
  }, [isSpanish, engData, spanishData]);
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  const selectedTags = useSelector((state) => state.AdobeResources.selectedTags);
  const navigate = useNavigate();
  const allEventsWithTimeZone = useSelector((state)  => state.AdobeResources.allEventsWithTimeZone);
  const handleClick = () => {
    //set the selected tags
    var selectedTopics = topicsName.filter(topic => selectedTags.indexOf(topic.data) !== -1);
    store.dispatch(updateRegisterPageSelectedTopics(selectedTopics));
    userRegistrationClick('Courses', 'Register for more', window.location.host + '/onboarding')
    //pageLoadStarted( 'OnboardingPage', window.location.href,selectedTopics?.toString());
    localStorage.setItem('prevUrl',window.location.href);
    navigate('/onboarding', { replace: true });
    
  }

  let topicsName = [];
  if (aemData && aemData.registerModule && aemData.registerModule.topics) {
    //Add clear to tag list
    //var topics = aemData.registerModule.topics;
    var clearTagData = { id: '1', label: t('Clear'), data: 'Clear' };
    //topics.push(clearTagData);
    topicsName = aemData.registerModule.topics.map((card) => ({
      id: card.id,
      label: card.label,
      data: card.data,
    }));
    topicsName.push(clearTagData);
  }
  return (
    <section aria-label="start-learning" className="course-filter pv-24X">
      <Grid
        className=""
        bleed="1440"
        colSizes={{
          mobile: 4,
          tablet: 12,
          desktop: 12,
        }}>
        <Row>
          <Col>
            <div className="mb-12X filter-titel" aria-label={aemData?.registerModule?.courseFilterTitle} tabIndex={0}>
              <Title size="2XLarge" bold={true} color="#000000">
                {aemData?.registerModule?.courseFilterTitle}
              </Title>
            </div>

            <div aria-label="multiselect" className="mb-12X multiselect">
              <TagSelectionComponent
                optionItems={topicsName}
                onChange={(e) => { }}
                maxSelect={3}
              />
            </div>
          </Col>
        </Row>
      </Grid>
      {allEventsWithTimeZone && <TagBasedCoursesComponent tags={selectedTags} isLandingPage={true} />}
      <div aria-label="buttonArea" className="button-area">
        <Button
          size="large"
          disabled={false}
          use="primary"
          onClick={(e) => handleClick()}
        >
          {aemData?.registerModule?.buttonText}
        </Button>
      </div>

    </section>
  );
};

export default CourseFilterComponent;
