// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */
.course-content-header {
  background-color: #000000;
  color: #f6f6f6;
  padding: 0 104px 48px 84px;
}
@media screen and (max-width: 767px) {
  .course-content-header {
    padding: 0 16px 48px 16px;
  }
}

.course-format p {
  display: inline-block;
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/CourseContentHeaderComponent.scss"],"names":[],"mappings":"AAAA,aAAA;AAEA;EACE,yBAAA;EACA,cAAA;EACA,0BAAA;AAAF;AACE;EAJF;IAKI,yBAAA;EAEF;AACF;;AACA;EACE,qBAAA;EACA,iBAAA;AAEF","sourcesContent":["/** @format */\n\n.course-content-header {\n  background-color: #000000;\n  color: #f6f6f6;\n  padding: 0 104px 48px 84px;\n  @media screen and (max-width: 767px) {\n    padding: 0 16px 48px 16px;\n  }\n}\n\n.course-format p {\n  display: inline-block;\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
