import { useSelector } from "react-redux";
import CourseDetails from "./CourseContentPage";

const CourseContentWrapperComponent = () => {

    const allQuickTips = useSelector((state) => state.AdobeResources.allQuickTips);
    const aemData = useSelector((state) => state.Aem.data);
    const offersMock = useSelector((state) => state.Aem.offerData);
    const quickTips = useSelector((state) => state.Aem.quickTipData);

    return <>
    {allQuickTips && quickTips && offersMock && aemData && <CourseDetails allQuickTips = {allQuickTips} quickTips={quickTips} offersMock={offersMock} aemData={aemData} />}
    </>;
}

export default CourseContentWrapperComponent;