import React, { useState } from 'react';
import { TileContainer } from "@vds/tiles";
import { Body, Micro, Title } from '@vds/typography';
import './CalendarViewCards.scss';
import { Icon } from '@vds/icons';
import { Badge } from '@vds/badges';
import { ButtonIcon } from '@vds/button-icons';
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
const CalendarViewCardComponent = (props) => {
  const { t, i18n } = useTranslation();
  const history = useNavigate();

  let dateTime = "";
  if(props?.data?.duration?.dateTime) {
    dateTime = props?.data?.duration?.dateTime;
  } else if (props?.data?.duration) {
    dateTime = props?.data?.duration;
  } else {
    dateTime = props?.data?.dateAndTimeValue;
  }

  let image = props?.data?.image;
  if(props?.data?.desktopImage) {
    image = props?.data?.desktopImage
  }

  //Duration conversion for language
  const getTimeInLanguage = (time) => {
    time = time?.replace('minutes remaining', t('minutes remaining'));
    time = time?.replace('minutes', t('minutes'));
    return time;
  }
  

  console.log("CalendarViewCardComponent props", props);

  return (
    <div className='calendar-card'>
      <div className='event-item'>
        <div className='event-profile pa-3X' style={{ backgroundImage: 'url(' + image + ')' }}>
          {props?.data?.saveIcon &&
            <span className='event-badge'>
              <ButtonIcon
                kind="lowContrast"
                size="small"
                surfaceType="media"
                renderIcon={(props) => <Icon name="bookmark" {...props} />}
              />
            </span>
          }
        </div>
        <div className='event-info pa-6X sm-pa-3X'>
          {/* {(props?.data?.additionalStatus || props?.data?.duration) && */}
            <div className='event-top mb-2X'>
              <span className='event-left'>
                <span className='event-time'>
                  <span className='calendar-icon mr-1X'>
                    {(props?.data.enrollmentStatus == "ENROLLED" || props?.data.enrollmentStatus == "WAITING" || props?.data.enrollmentStatus == "STARTED") && <Icon
                      name={props?.data?.additionalStatus?.tagIcon}
                      size="medium"
                      color={props?.data?.additionalStatus?.color}
                    />}
                    {props?.data.enrollmentStatus == "NOT_ENROLLED" && props?.data.format == "Event" && <Icon
                      name={t("calendar")}
                      size="medium"
                      color={props?.data?.duration?.color}
                    />}
                    {props?.data.format != "Event" && <Icon
                      name="count-down"
                      size="medium"
                      color={props?.data?.duration?.color}
                    />}
                  </span>
                  <Micro
                    color={props?.data?.duration?.color} bold={true}>
                    {t(getTimeInLanguage(dateTime))}
                  </Micro>
                </span>

                {props?.data?.additionalStatus &&
                  <span className='additional-tag mr-1X'>
                    <span className='tag-icon mr-1X'>
                      <Icon
                        name={props?.data?.additionalStatus?.tagIcon}
                        size="medium"
                        color={props?.data?.additionalStatus?.color}
                      />
                    </span>
                    <Micro
                      color={props?.data?.additionalStatus?.color} bold={false}>
                      {t(props?.data?.additionalStatus?.tagName)}
                    </Micro>
                    {props.data?.duration?.extLink && (
                    <TextLink
                      type='inline'
                      size='small'
                      color={props?.data?.duration?.color}
                      onClick={e => alert('TextLink clicked')}>
                      <span className="ml-2X">{props?.data?.duration?.extLink?.text}</span>
                    </TextLink>)}
                  </span>
                }
              </span>
              <span className='event-right'>
              {!isMobile && (props?.data?.tag && props?.data?.tag != '') &&
                <span className='event-tag ph-3X pv-2X'>
                  <Body
                    size="small"
                    color="#000000"
                    bold={true}>
                      {t(props?.data.tag)} 
                  </Body>
                </span>
              }
            </span>
            </div>
          {/*  } */}

          <div className='event-content'>
            <div className='event-text'>
              <Title
                size="small"
                color="#000000"
                bold={false}>
                {props?.data.title ? props?.data.title : props?.data.label}
              </Title>
            </div>
            <span className='right-arrow' 
            role='link'
            aria-label={`${props?.data.title ? props?.data.title : props?.data.label} ${t(props?.data?.format)}`}
             onClick={e => {
              window.scrollTo(0, 0);
              history("/course-details/"+(props.data.id ? props.data.id : props.data.courseId))}} tabIndex={0}>
              <Icon
                name="right-arrow"
                size="medium"
                color="#000000"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarViewCardComponent;
