/** @format */
import { useNavigate } from 'react-router-dom';

import { BreadcrumbItem, Breadcrumbs } from '@vds/breadcrumbs';
import { TextLinkCaret } from '@vds/buttons';
import { Col, Grid, Row } from '@vds/grids';
import { Body, Title } from '@vds/typography';
import { useTranslation } from 'react-i18next';

import './CourseContentHeaderComponent.scss';

const CourseContentHeaderComponent = (props) => {
  const { t, i18n } = useTranslation();
  const { headerBackLinkLabel, headerTitle, headerBreadcrumbs,breadCrumb, recordedEventTag } = props;

  const navigate = useNavigate();

  const onBackLinkClicked = () => {
    console.log('back link clicked');
    navigate(-1);
  };
  return (
    <div className="course-content-header">
      <Grid colSizes={{mobile: 4,tablet: 12,desktop: 12}}>
        <Row>
          <Col>
          <div className='mt-8x'>
            {breadCrumb}
          </div>
          </Col>
        </Row>
        <Row>
          <Col
            colSizes={{
              mobile: 4,
              tablet: 1,
              desktop: 1,
            }}
          >
          {!breadCrumb &&
            <div className="mt-8X">
              <TextLinkCaret
                surface="dark"
                iconPosition="left"
                onClick={onBackLinkClicked}
              >
                {headerBackLinkLabel}
              </TextLinkCaret>
            </div>
          }
          </Col>
          <Col
            colSizes={{
              mobile: 4,
              tablet: 8,
              desktop: 8,
            }}
          >
            <div tabIndex={0} aria-label={headerTitle} className="mt-16X sm-mt-12X mb-4X">
              <Title size="2XLarge" color="#ffffff" bold={true}>
                {headerTitle}
              </Title>
            </div>
            <div tabIndex={0} className='course-format' aria-label='course-format'>
              <Body size="small" bold={false} color="#ffffff"> {recordedEventTag ? t("Event") : headerBreadcrumbs} </Body>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default CourseContentHeaderComponent;
