import { useSelector } from 'react-redux';
import Moment from 'moment';
import { useRef } from 'react';
import { Carousel } from '@vds/carousels';

import CourceCardComponent from './CourseCardComponent';
import SecondaryCourseCardComponent from './SecondaryCourseCardComponent';

import './TagBasedCoursesComponent.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CourseCardComponent from '../components/common/CourseCards/CourseCards';
import { mapperForCourseCardDesktop } from '../utility/utils';
const TagBasedCoursesComponent = ({ tags, isLandingPage }) => {
  const { t, i18n } = useTranslation();
  const [courseDetailssecondaryList, setCourseDetailssecondaryList] = useState([]);
  const selectedTags = useSelector((state) => state.AdobeResources.selectedTags);
  const isMobile = useSelector((state) => state.MediaType.isMobile);
  const isIpad = useSelector((state) => state.MediaType.isIpad);
  const minifiedCatalog = useSelector(
    (state) => state.AdobeResources.catalogData
  );
  const allEventsWithTimeZone = useSelector((state)  => state.AdobeResources.allEventsWithTimeZone);
  const allCourses = useSelector((state)  => state.AdobeResources.allCourses);
  let MAX_NUMBER_OF_COURSE_TILES = 6;
  let MAX_NUMBER_OF_EVENT_TILES = 6;
  let MAX_NUMBER_OF_TILES = 6;
  const carouselRef = useRef(null);

  useEffect(()=>{
    if(carouselRef?.current){
      carouselRef?.current?.addEventListener("click", function(){
        localStorage.setItem('categoryPath',location.pathname)
      });      
    }
  });

  useEffect(() => {
  //Check for the user for sorting
  const userLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : 'en';
  Moment.locale(userLanguage);

  console.log("allEventsWithTimeZone", allEventsWithTimeZone);

  //Filter event for future date
  let events = allEventsWithTimeZone?.filter(e => new Date(e.startDate).getTime() >= new Date().getTime());
  let courses = allCourses;

  //Filter tags
  if (tags && tags != null && tags.length > 0) {
    events =events?.filter(e=>tags.some(r=> e.tags?.includes(r)));
    courses = allCourses?.filter(e=>tags.some(r=> e.tags?.includes(r)));
  }

  //sort the courses and events based on language
  let courseDetailssecondaryListTemp = [];
  switch(userLanguage) {
    case "en":
      events = events?.filter(e=>e.locale?.indexOf('en-US') != -1);
      courses = courses?.filter(e=>e.locale?.indexOf('en-US') != -1);

      //Logic for showing 6 tiles 3 events and 3 courses
      if(events?.length >= 3 && courses?.length >= 3) {
        events = events?.splice(0,3);
        courses = courses?.splice(0,3);
      } else if (events?.length < 3 && courses?.length >= 3) {
        courses = courses?.splice(0, MAX_NUMBER_OF_TILES - events?.length);
      } else if (events?.length >= 3 && courses.length < 3) {
        events = events?.splice(0, MAX_NUMBER_OF_TILES - courses?.length);
      } 

      for(let index = 0; courseDetailssecondaryListTemp.length < MAX_NUMBER_OF_TILES && (courses.length != 0 || events.length != 0) ; index++) {
        if(index%2 === 0) {
          let object = courses.splice(0,1)[0];
          console.log(object);
          if(object) {
            courseDetailssecondaryListTemp.push(object);
          }
        } else {
          let object = events.splice(0,1)[0];
          if(object) {
            courseDetailssecondaryListTemp.push(object);
          }
        }
      }

      break;
    case "es":
      let spanishEvents = [];
      let bilingualEvents = [];
      let englishEvents = [];

      events?.forEach((event) => {
        if(event.locale.indexOf('en-US') != -1 && event.locale.indexOf('es-ES') != -1) {
          bilingualEvents.push(event);
        } else if (event.locale.indexOf('es-ES') != -1 ) {
          spanishEvents.push(event);
        } else {
          englishEvents.push(event);
        }
      });

      let spanishCourses = [];
      let bilingualCourses = [];
      let englishCourses = [];

      courses?.forEach((course) => {
        if (course.locale.indexOf('es-ES') != -1) {
          spanishCourses.push(course);
        } else {
          englishCourses.push(course);
        }
      });

      events = spanishEvents.concat(bilingualEvents).concat(englishEvents);
      courses = spanishCourses.concat(bilingualCourses).concat(englishCourses);

      let index = 0;
      while(courseDetailssecondaryListTemp.length < MAX_NUMBER_OF_TILES && ( courses?.length > 0 || events?.length > 0)) {
        if(index%2 === 0) {
          if(courses[0] && courses[0].locale.indexOf('es-ES') != -1) {
            courseDetailssecondaryListTemp.push(courses.splice(0,1)[0]);
          } else if (events[0] && events[0].locale.indexOf('es-ES') != -1) {
            courseDetailssecondaryListTemp.push(events.splice(0,1)[0]);
          } else if (courses[0]) {
            courseDetailssecondaryListTemp.push(courses.splice(0,1)[0]);
          } else if (events[0]) {
            courseDetailssecondaryListTemp.push(events.splice(0,1)[0]);
          }
        } else {
          if(events[0] && events[0].locale.indexOf('es-ES') != -1) {
            courseDetailssecondaryListTemp.push(events.splice(0,1)[0]);
          } else if (courses[0] && courses[0].locale.indexOf('es-ES') != -1) {
            courseDetailssecondaryListTemp.push(courses.splice(0,1)[0]);
          } else if (events[0]) {
            courseDetailssecondaryListTemp.push(events.splice(0,1)[0]);
          } else if (courses[0]) {
            courseDetailssecondaryListTemp.push(courses.splice(0,1)[0]);
          }
        }
        index++;
      }

      break;
  }

  setCourseDetailssecondaryList(courseDetailssecondaryListTemp);

  }, [allEventsWithTimeZone, selectedTags]);
  
  return (
    <div className="course-container" ref={carouselRef}>
      {courseDetailssecondaryList.length > 0 && <div aria-label="coursecards" className="mb-12X course-carousel">
        <Carousel
          peek="standard"
          layout={isMobile ? '1UP' : isIpad ? '2UP' : '3UP'}
          gutter="24px"
          surface="light"
          paginationFill="light"
          paginationInset="12px"
          paginationDisplay="persistent"
          data={courseDetailssecondaryList.map(e => mapperForCourseCardDesktop(e))}
          renderItem={(props) => <CourseCardComponent data={props} />}
        />
      </div>}
    </div>
  );
};

export default TagBasedCoursesComponent;
